<template>
  <div
    ref="modalbg"
    class="z-30 center w-full h-full lg:px-20 lg:py-10 bg-black/50 text-center"
  >
    <div
      class="bg-white w-full lg:w-2/3 inline-block h-full lg:rounded-3xl overflow-y-auto p-8 basis-0 relative text-center"
    >
      <div class="h-full w-full text-left">
        <div
          @click="$emit('closeModal')"
          class="iconborder-modal z-40 cursor-pointer"
        >
          <font-awesome-icon size="lg" icon="xmark" class="inborder" />
        </div>
        <div v-if="loading" class="inline-block lds-dual-ring"></div>
        <div v-if="loading" class="center mt-10 text-black">Lade Daten...</div>
        <div v-if="!loading">
          <div class="w-full text-center pb-3">
            <input
              class="w-52 inline-block px-2 py-1 text-xl"
              v-model="cv.documentTitle"
            /><span class="text-xl font-bold">.pdf</span>
            <span class="ml-3 inline-block font-bold text-2xl mt-0">{{
              title
            }}</span>
          </div>
          <div class="">
            <span class="font-bold text-2xl">Allgemeine Einstellungen</span>
            <div class="grid lg:grid-cols-2">
              <div class="">
                <span class="block font-semibold">Sprache des Lebenslaufs</span>
                <select
                  v-model="cv.language"
                  class="w-11/12 bg-gray-100 p-3 mt-2"
                >
                  <option value="de">Deutsch</option>
                  <option value="en">Englisch</option>
                </select>
              </div>
            </div>
            <span class="font-bold text-2xl">Persönliche Infos</span>
            <div class="grid lg:grid-cols-2">
              <div class="">
                <span class="block font-semibold">Vor- und Nachname</span>
                <input
                  v-model="cv.name"
                  class="w-11/12 bg-gray-100 p-3 mt-2"
                  placeholder="Vor- und Nachname"
                />
              </div>
              <div class="inline-block">
                <span class="block font-semibold">Foto</span>
                <input
                  v-model="cv.pictureName"
                  class="text-center cursor-pointer w-3/4"
                  @click="$refs.cvPic.click()"
                />
                <font-awesome-icon
                  size="lg"
                  icon="xmark"
                  class="text-3xl align-top mt-4 ml-5 inline-block cursor-pointer"
                  @click="resetPicture()"
                />
                <input
                  hidden
                  ref="cvPic"
                  class="w-11/12 bg-gray-100 p-3 mt-2"
                  type="file"
                  placeholder="Bewerbungsfoto"
                  @change="addFileToCV()"
                />
                <span class="text-red-500 block" v-if="cv.pictureIsTooBig"> Das Bild darf maximal 8 MB groß sein. </span>
              </div>
              <div class="mt-6">
                <span class="block font-semibold">E-Mail</span>
                <input
                  v-model="cv.mail"
                  class="w-11/12 bg-gray-100 p-3 mt-2"
                  placeholder="E-Mail"
                />
              </div>
              <div class="mt-6">
                <span class="block font-semibold">Telefonnummer</span>
                <input
                  v-model="cv.phone"
                  class="w-11/12 bg-gray-100 p-3 mt-2"
                  placeholder="Telefonnummer"
                />
              </div>
              <div class="mt-6">
                <span class="block font-semibold">Geburtsdatum</span>
                <input
                  v-model="cv.birthdate"
                  class="w-11/12 bg-gray-100 p-3 mt-2"
                  placeholder="Geburtsdatum"
                  type="date"
                />
              </div>
            </div>
            <span class="font-bold text-2xl mt-5 inline-block">Anschrift</span>
            <div class="">
              <div class="mt-6 col-span-3">
                <span class="block font-semibold">Straße</span>
                <input
                  class="w-afull bg-gray-100 p-3 mt-2"
                  v-model="cv.street"
                  placeholder="Musterstraße 1"
                />
              </div>
            </div>
            <div class="grid grid-cols-3">
              <div class="mt-6">
                <span class="block font-semibold">PLZ</span>
                <input
                  v-model="cv.zip"
                  class="w-11/12 bg-gray-100 p-3 mt-2"
                  placeholder="PLZ"
                />
              </div>
              <div class="mt-6 col-span-2">
                <span class="block font-semibold">Stadt.</span>
                <input
                  v-model="cv.city"
                  class="w-11/12 bg-gray-100 p-3 mt-2"
                  placeholder="Stadt"
                />
              </div>
            </div>
            <span class="font-bold text-2xl mt-5 inline-block"
              >Bildungsweg</span
            >
            <div>
              <div
                v-for="eduStep in cv.eduSteps"
                :key="eduStep"
                class="w-full pr-4 border-b-2 border-black pb-3"
              >
                <div class="mt-2">
                  <select v-model="eduStep.type" class="font-bold w-auto">
                    <option value="Auswählen">Auswählen</option>
                    <option value="Weiterführende Schule">
                      Weiterführende Schule
                    </option>
                    <option value="Berufsschule">Berufsschule</option>
                    <option value="Hochschule">Hochschule</option></select
                  ><button
                    class="ml-3 text-red-500 border-red-500"
                    @click="removeFromArray(cv.eduSteps, eduStep.index)"
                  >
                    Entfernen</button
                  ><br />
                  <input
                    v-model="eduStep.name"
                    class="w-full"
                    placeholder="Name der Bildungseinrichtung"
                  />
                </div>
                <div class="w-full">
                  <div class="inline-block mt-2">
                    <div class="inline-block pr-2">
                      <span class="font-semibold">Von</span><br />
                      <input
                        v-model="eduStep.start"
                        type="month"
                        class="w-48 mt-0"
                      />
                      -
                    </div>
                    <div v-if="!eduStep.current" class="inline-block pr-4">
                      <span class="font-semibold">Bis</span><br />
                      <input
                        v-model="eduStep.end"
                        type="month"
                        class="w-48 mt-0"
                      />
                    </div>
                    <div class="inline-block">
                      <input
                        type="checkbox"
                        :id="eduStep.name + 'current'"
                        v-model="eduStep.current"
                      /><label
                        :for="eduStep.name + 'current'"
                        class="pr-3 mt-2 inline-block"
                        >Bis heute</label
                      >
                    </div>
                  </div>
                  <br />
                  <div class="mt-2">
                    <div class="inline-block align-top w-1/2">
                      <span v-if="eduStep.current" class="font-semibold"
                        >Angestrebter&nbsp;</span
                      ><span class="font-semibold"> Abschluss</span><br />
                      <input
                        placeholder="B.Sc. Maschinenbau"
                        v-model="eduStep.grad"
                        class="mt-0 w-11/12 align-bottom"
                      />
                    </div>
                    <div class="inline-block align-top w-1/2">
                      <span v-if="eduStep.current" class="font-semibold"
                        >Aktueller&nbsp;</span
                      ><span class="font-semibold">Notenschnitt</span
                      ><span v-if="eduStep.current" class="font-semibold"
                        >&nbsp;</span
                      ><br />
                      <input
                        type="number"
                        step="0.1"
                        placeholder="2,4"
                        v-model="eduStep.grade"
                        class="w-20 mt-0"
                      />
                    </div>
                    <div class="inline-block w-full">
                      <span class="mt-2 inline-block font-semibold"
                        >Sonstige Informationen</span
                      ><br />
                      <textarea
                        v-model="eduStep.notes"
                        placeholder="Zusatzqualifikation Datenschutz"
                        class="w-full p-2 inline-block bg-gray-100"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-4 w-full">
                <button
                  class="m-2"
                  @click="addEduStep('Weiterführende Schule')"
                >
                  Weiterführende Schule hinzufügen
                </button>
                <button class="m-2" @click="addEduStep('Berufsschule')">
                  Berufsschule hinzufügen
                </button>
                <button class="m-2" @click="addEduStep('Hochschule')">
                  Hochschule hinzufügen
                </button>
              </div>
            </div>
            <span class="font-bold text-2xl mt-5 inline-block"
              >Berufliche Stationen</span
            >
            <div>
              <div
                v-for="workStep in cv.workSteps"
                :key="workStep"
                class="w-full pr-4 border-b-2 border-black"
              >
                <span class="mt-2 inline-block font-semibold">Firmenname</span
                ><button
                  class="ml-3 text-red-500 border-red-500"
                  @click="removeFromArray(cv.workSteps, workStep.index)"
                >
                  Entfernen</button
                ><br />
                <input
                  v-model="workStep.name"
                  placeholder="Firmenname"
                  class="w-full"
                />
                <span class="mt-2 inline-block font-semibold">Position</span
                ><br />
                <input
                  v-model="workStep.position"
                  placeholder="Position"
                  class="w-full"
                />
                <div class="w-full">
                  <div class="inline-block">
                    <div class="inline-block pr-2">
                      <span class="font-semibold">Von</span><br />
                      <input
                        v-model="workStep.start"
                        type="month"
                        class="w-48"
                        placeholder="2005"
                      />
                      -
                    </div>
                    <div v-if="!workStep.current" class="inline-block">
                      <span class="font-semibold">Bis</span><br />
                      <input
                        v-model="workStep.end"
                        type="month"
                        class="w-48"
                        placeholder="2005"
                      />
                    </div>
                    <div class="inline-block ml-4">
                      <input
                        type="checkbox"
                        :id="workStep.position + workStep.company + 'current'"
                        v-model="workStep.current"
                      /><label
                        :for="workStep.position + workStep.company + 'current'"
                        >Bis heute</label
                      >
                    </div>
                  </div>
                  <div class="inline-block w-full">
                    <span class="mt-2 inline-block font-semibold">Aufgaben</span
                    ><br />
                    <textarea
                      v-model="workStep.tasks"
                      placeholder="Aufgaben (Optional)"
                      class="w-full p-2 inline-block bg-gray-100"
                    ></textarea>
                  </div>
                </div>
              </div>
              <div class="mt-4 w-full">
                <button @click="addWorkStep()">Station hinzufügen</button>
              </div>
            </div>
            <span class="font-bold text-2xl mt-5 inline-block"
              >Sprachkenntnisse</span
            >
            <div>
              <div class="grid lg:grid-cols-2">
                <div class="">
                  <span class="block font-semibold">Sprache</span>
                  <input
                    v-model="newLanguage"
                    class="w-11/12 bg-gray-100 p-3 mt-2"
                    placeholder="Englisch"
                  />
                </div>
                <div class="">
                  <span class="block font-semibold"
                    >Kenntnisse (Grundlagen - Verhandlungssicher)</span
                  >
                  <div
                    @mouseover="languagelevel = 1"
                    @click="addLanguage()"
                    v-bind:class="[
                      languagelevel > 0 ? 'circle-selected' : 'circle',
                    ]"
                  ></div>
                  <div
                    @mouseover="languagelevel = 2"
                    @click="addLanguage()"
                    v-bind:class="[
                      languagelevel > 1 ? 'circle-selected' : 'circle',
                    ]"
                  ></div>
                  <div
                    @mouseover="languagelevel = 3"
                    @click="addLanguage()"
                    v-bind:class="[
                      languagelevel > 2 ? 'circle-selected' : 'circle',
                    ]"
                  ></div>
                  <div
                    @mouseover="languagelevel = 4"
                    @click="addLanguage()"
                    v-bind:class="[
                      languagelevel > 3 ? 'circle-selected' : 'circle',
                    ]"
                  ></div>
                  <div
                    @mouseover="languagelevel = 5"
                    @click="addLanguage()"
                    v-bind:class="[
                      languagelevel > 4 ? 'circle-selected' : 'circle',
                    ]"
                  ></div>
                </div>
              </div>
              <div
                v-for="language in cv.languages"
                :key="language"
                class="m-3 bg-gray-200 inline-block p-2 rounded-lg"
              >
                <span class="font-bold mt-1 align-top">{{
                  language.language
                }}</span>
                <div class="inline-block align-top ml-2">
                  <div
                    v-bind:class="[
                      language.level > 0
                        ? 'smallcircle-selected'
                        : 'smallcircle',
                    ]"
                  ></div>
                  <div
                    v-bind:class="[
                      language.level > 1
                        ? 'smallcircle-selected'
                        : 'smallcircle',
                    ]"
                  ></div>
                  <div
                    v-bind:class="[
                      language.level > 2
                        ? 'smallcircle-selected'
                        : 'smallcircle',
                    ]"
                  ></div>
                  <div
                    v-bind:class="[
                      language.level > 3
                        ? 'smallcircle-selected'
                        : 'smallcircle',
                    ]"
                  ></div>
                  <div
                    v-bind:class="[
                      language.level > 4
                        ? 'smallcircle-selected'
                        : 'smallcircle',
                    ]"
                  ></div>
                </div>
                <font-awesome-icon
                  size="lg"
                  icon="xmark"
                  class="text-3xl ml-1 cursor-pointer align-top inline-block"
                  @click="
                    cv.languages.splice(cv.languages.indexOf(language), 1)
                  "
                />
              </div>
            </div>
            <span class="font-bold text-2xl mt-5 inline-block"
              >IT-Kenntnisse (Optional)</span
            >
            <div>
              <div class="grid lg:grid-cols-2">
                <div class="">
                  <span class="block font-semibold">Bezeichnung</span>
                  <input
                    v-model="newSkill"
                    class="w-11/12 bg-gray-100 p-3 mt-2"
                    placeholder="Excel"
                  />
                </div>
                <div class="">
                  <span class="block font-semibold"
                    >Kenntnisse (Grundlagen - Experte)</span
                  >
                  <div
                    @mouseover="skilllevel = 1"
                    @click="addSkill()"
                    v-bind:class="[
                      skilllevel > 0 ? 'circle-selected' : 'circle',
                    ]"
                  ></div>
                  <div
                    @mouseover="skilllevel = 2"
                    @click="addSkill()"
                    v-bind:class="[
                      skilllevel > 1 ? 'circle-selected' : 'circle',
                    ]"
                  ></div>
                  <div
                    @mouseover="skilllevel = 3"
                    @click="addSkill()"
                    v-bind:class="[
                      skilllevel > 2 ? 'circle-selected' : 'circle',
                    ]"
                  ></div>
                  <div
                    @mouseover="skilllevel = 4"
                    @click="addSkill()"
                    v-bind:class="[
                      skilllevel > 3 ? 'circle-selected' : 'circle',
                    ]"
                  ></div>
                  <div
                    @mouseover="skilllevel = 5"
                    @click="addSkill()"
                    v-bind:class="[
                      skilllevel > 4 ? 'circle-selected' : 'circle',
                    ]"
                  ></div>
                </div>
              </div>
              <div
                v-for="skill in cv.skills"
                :key="skill.name"
                class="m-3 bg-gray-200 inline-block p-2 rounded-lg"
              >
                <span class="font-bold mt-1 align-top">{{ skill.skill }}</span>
                <div class="inline-block align-top ml-2">
                  <div
                    v-bind:class="[
                      skill.level > 0 ? 'smallcircle-selected' : 'smallcircle',
                    ]"
                  ></div>
                  <div
                    v-bind:class="[
                      skill.level > 1 ? 'smallcircle-selected' : 'smallcircle',
                    ]"
                  ></div>
                  <div
                    v-bind:class="[
                      skill.level > 2 ? 'smallcircle-selected' : 'smallcircle',
                    ]"
                  ></div>
                  <div
                    v-bind:class="[
                      skill.level > 3 ? 'smallcircle-selected' : 'smallcircle',
                    ]"
                  ></div>
                  <div
                    v-bind:class="[
                      skill.level > 4 ? 'smallcircle-selected' : 'smallcircle',
                    ]"
                  ></div>
                </div>
                <font-awesome-icon
                  size="lg"
                  icon="xmark"
                  class="text-3xl ml-1 cursor-pointer align-top inline-block"
                  @click="cv.skills.splice(cv.skills.indexOf(skill), 1)"
                />
              </div>
            </div>
            <span class="font-bold text-2xl mt-5 inline-block"
              >Hobbies / Interessen (Optional)</span
            >
            <div>
              <input
                class="w-afull bg-gray-100 p-3 mt-2"
                v-model="interestInput"
                placeholder="Tennis, Snowboarden, Computerspielen, Fahrrad fahren, ..."
                @keyup.enter="addInterest()"
                @input="checkInterest()"
              />
              <div
                v-for="interest in cv.interests"
                :key="interest"
                class="m-3 bg-gray-200 inline-block p-2 rounded-lg"
              >
                <span class="font-bold mt-1 align-top">{{ interest }}</span>
                <font-awesome-icon
                  size="lg"
                  icon="xmark"
                  class="text-3xl ml-1 cursor-pointer align-top inline-block"
                  @click="
                    cv.interests.splice(cv.interests.indexOf(interest), 1)
                  "
                />
              </div>
            </div>
            <span class="font-bold text-2xl mt-5 inline-block"
              >Sonstige Qualifikationen (Optional)</span
            >
            <div>
              <input
                class="w-afull bg-gray-100 p-3 mt-2"
                v-model="otherQualificationInput"
                placeholder="Fachkraft für Arbeitssicherheit, Datenschutzbeauftragter, ..."
                @keyup.enter="addOtherQualification()"
                @input="checkOtherQualifications()"
              />
              <div
                v-for="otherQualification in cv.otherQualifications"
                :key="otherQualification"
                class="m-3 bg-gray-200 inline-block p-2 rounded-lg"
              >
                <span class="font-bold mt-1 align-top">{{
                  otherQualification
                }}</span>
                <font-awesome-icon
                  size="lg"
                  icon="xmark"
                  class="text-3xl ml-1 cursor-pointer align-top inline-block"
                  @click="
                    cv.otherQualifications.splice(
                      cv.otherQualifications.indexOf(otherQualification),
                      1
                    )
                  "
                />
              </div>
            </div>
          </div>
          <div class="w-full text-center py-5">
            <button
              v-if="id != 0"
              class="mx-2 text-red-500 border-red-500"
              @click="$emit('deleteCV', id)"
            >
              Löschen
            </button>
            <button class="mx-2" @click="showPreview()">Vorschau</button>
            <button
              class="mx-2 text-green-600 border-green-600"
              @click="saveCV()"
            >
              Speichern
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/API/Private";
import Encryption from "@/Encryption";

export default {
  props: { id: Number },
  expose: ["addFile"],
  emits: ["fileAdded", "closeModal"],
  data() {
    return {
      title: " erstellen",
      loading: false,
      cv: {
        language: "de",
        eduSteps: [],
        workSteps: [],
        interests: [],
        otherQualifications: [],
        languages: [
          {
            language: "Deutsch",
            level: 5,
          },
        ],
        skills: [],
        documentTitle: "Lebenslauf",
      },
      eduIndex: 0,
      workIndex: 0,
      newStepType: "Auswählen",
      console: console,
      pictureName: "Klicken für Upload",
      interestInput: "",
      otherQualificationInput: "",
      languagelevel: 0,
      skilllevel: 0,
      newLanguage: "",
      newSkill: "",
    };
  },
  methods: {
    checkInterest() {
      if (this.interestInput.endsWith(",")) {
        this.cv.interests.push(this.interestInput.replace(",", ""));
        this.interestInput = "";
      } else if (this.interestInput.endsWith(";")) {
        this.cv.interests.push(this.interestInput.replace(";", ""));
        this.interestInput = "";
      }
    },
    checkOtherQualifications() {
      if (this.otherQualificationInput.endsWith(",")) {
        this.cv.otherQualifications.push(
          this.otherQualificationInput.replace(",", "")
        );
        this.otherQualificationInput = "";
      } else if (this.otherQualificationInput.endsWith(";")) {
        this.cv.otherQualifications.push(
          this.otherQualificationInput.replace(";", "")
        );
        this.otherQualificationInput = "";
      }
    },
    addLanguage() {
      if (this.newLanguage == "") return;
      this.cv.languages.push({
        language: this.newLanguage,
        level: this.languagelevel,
      });
      this.newLanguage = "";
      this.languagelevel = 0;
    },
    addSkill() {
      if (this.newSkill == "") return;
      this.cv.skills.push({
        skill: this.newSkill,
        level: this.skilllevel,
      });

      this.newSkill = "";
      this.skilllevel = 0;
    },
    addInterest() {
      this.cv.interests.push(this.interestInput);
      this.interestInput = "";
    },
    addOtherQualification() {
      this.cv.otherQualifications.push(this.otherQualificationInput);
      this.otherQualificationInput = "";
    },
    addEduStep(type) {
      this.cv.eduSteps.push({
        index: this.eduIndex,
        type: type,
        grad: "",
      });
      this.eduIndex = this.eduIndex + 1;
    },
    addWorkStep() {
      this.cv.workSteps.push({
        index: this.workIndex,
      });
      this.workIndex = this.workIndex + 1;
    },
    removeFromArray(array, index) {
      for (let i = 0; i < array.length; i++) {
        if (array[i].index == index) {
          array = array.splice(i, 1);
          break;
        }
      }
    },
    checkClosing(event) {
      if (event.target == this.$refs.modalbg) {
        this.$emit("closeModal");
      }
    },
    resetPicture() {
      this.pictureName = "Klicken für Upload";
      delete this.cv.picture;
    },
    showPreview() {
      API.getCVPreview(this.cv, (res) => {
        if (res.id) {
          window.open(
            process.env.VUE_APP_PRIVATE_URL + "/files/static/" + res.id
          );
        }
      });
    },
    saveCV() {
      let vm = this;
      if (this.id != 0) {
        API.editCV(this.id, this.cv, (res) => {
          if (res) {
            vm.$emit("fileSaved");
          }
        });
      } else {
        API.saveCV(this.cv, (res) => {
          if (res) {
            vm.$emit("fileSaved");
          }
        });
      }
    },
    addFileToCV() {
      let vm = this;
      let fr = new FileReader();
      this.$refs.cvPic.files.forEach((file) => {
        fr.readAsArrayBuffer(file);
        fr.onload = function () {
          var data = fr.result;
          vm.cv.picture = Encryption.arrayBufferToBase64(data);
          vm.cv.pictureName = file.name;
          vm.cv.pictureIsTooBig = (file.size / 1024 / 1024) > 8;
          if(vm.cv.pictureIsTooBig){
            delete vm.cv.picture;
            delete vm.cv.pictureName;
          }
        };
      });
    },
  },
  computed: {},
  created: function () {
    if (this.id != 0) {
      this.loading = true;
      this.title = " bearbeiten";
      let vm = this;
      API.getCVData(this.id, (data) => {
        vm.cv = data;
        if (!vm.cv.language) {
          vm.cv.language = "de";
        }
        vm.loading = false;
      });
    }
  },
};
</script>

<style scoped>
.circle,
.smallcircle,
.smallcircle-selected,
.circle-selected {
  margin-top: 13px;
  margin-right: 10px;
  vertical-align: top;
  display: inline-block;
  width: 33px;
  height: 33px;
  padding: 3px;
  border: 4px solid #292f4c;
  border-radius: 20px;
  cursor: pointer;
}

.circle-selected,
.smallcircle-selected {
  background: #292f4c;
}

.smallcircle,
.smallcircle-selected {
  margin-top: 10px;
  margin-right: 4px;
  width: 12px;
  height: 12px;
  padding: 1px;
  border-radius: 10px;
  border: 2px solid #292f4c;
  cursor: default;
}

input[type="checkbox"] {
  padding: unset;
  width: unset;
}
</style>
