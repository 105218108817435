<template>
  <div>
    <Transition>
      <TopBanner mode="error" :text="bannerText" v-if="bannerText" />
    </Transition>
    <AddFileModal v-if="showAddFileModal" kind="Dokument" @closeModal="showAddFileModal = false" @addFile="addFile" />
    <div class="h-full p-5 w-full min-w-side border-l-2 border-secondary bg-white overflow-y-auto relative" id="detailsSidebar">
      <div class="w-full text-center h-full absolute top-96 left-0 z-40" v-if="displayInterviewPopup">
        <div class="bg-white rounded-lg shadow-lg border-2 border-black w-5/6 inline-block mt-50 p-2 relative">
          <div @click="displayInterviewPopup = false" class="iconborder">
            <font-awesome-icon icon="xmark" class="inborder" />
          </div>
          <span class="font-bold block"> Wann findet das Interview statt?</span>
          <input class="mt-4 inline-block font-semibold text-left" type="datetime-local" v-model="interviewDate" /><br />
          <button @click="setupInterview()" class="mt-2">Bestätigen</button>
        </div>
      </div>
      <div @click="$emit('closeDetails')" class="iconborder">
        <font-awesome-icon icon="xmark" class="inborder" />
      </div>
      <div class="text-center w-full">
        <div class="ml-5 cursor-pointer rounded-full align-middle relative inline-block h-24 w-24 bg-secondary">
          <span class="text-primary m-0 center font-semibold text-4xl">{{
        initials
      }}</span>
        </div>
        <div class="mt-8">
          <input class="text-center text-2xl font-bold w-full" placeholder="Max Mustermann" v-model="editableApplication.name" @change="updateApplication('name')" />
          <br />
          <input class="text-center text-lg font-semibold w-full" placeholder="E-Mail" v-model="editableApplication.mail" @change="updateApplication('mail')" />
          <br />
          <input class="text-center text-sm font-semibold w-full" placeholder="Telefonnummer" v-model="editableApplication.phone" @change="updateApplication('phone')" />
        </div>
        <div v-if="!editableApplication.encrypted">
          <span class="font-bold text-red-500">Diese Bewerbung ist noch nicht Client-verschlüsselt, da sie importiert wurde.</span>
          <button class="border-red-500 text-red-500 font-bold mt-3" @click="encryptApplication">Jetzt verschlüsseln</button>
        </div>
        <div class="w-full text-left mt-6 mb-2" v-if="mode != 'archive'">
          <span class="text-lg">Status</span>
          <div class="rounded-full float-right bg-secondary text-primary font-semibold py-1 px-4">
            <select class="bg-secondary font-bold" v-model="editableApplication.status" @change="updateApplication('status')">
              <option value="Abgesagt">Abgesagt</option>
              <option value="Eingegangen">Eingegangen</option>
              <option value="Interview">Interview</option>
              <option value="Angebot">Angebot</option>
            </select>
          </div>
        </div>
        <div class="pl-4">
          <div class="border-b border-b-black w-full mt-2 my-2"></div>
        </div>

        <div v-if="editableApplication.status == 'Eingegangen' && mode != 'archive'" class="w-full">
          <div class="w-full text-left flex flex-row">
            <span class="grow text">HR-geprüft:</span>
            <div class="align-middle text-right w-36">
              <input id="approved" @change="updateApplication('approved')" v-model="editableApplication.approved" type="checkbox" /><label for="approved" class="box-right"></label>
            </div>
          </div>
          <div class="pl-4">
            <div class="border-b border-b-black w-full my-2"></div>
          </div>
        </div>
        <div v-if="editableApplication.status == 'Interview'" class="text-center relative">
          <div class="w-full text-left">
            <span class="text-left text-xl">Interviews</span>
            <div class="iconborder" @click="interviews++" v-if="interviews != 3">
              <font-awesome-icon icon="plus" class="inborder" />
            </div>
            <div>
              <div class="w-full text-left mt-2 py-1 flex flex-row">
                <span class="font-bold">Termin 1: </span>
                <div class="lign-middle text-right grow">
                  <input class="w-48 inline-block font-semibold text-left" type="datetime-local" v-model="editableApplication.scheduled" @change="updateApplication('scheduled')" />
                </div>
              </div>
              <div class="w-full text-left py-1 flex flex-row">
                <span class="grow text">Bestätigt:</span>
                <div class="align-middle text-left flex w-36">
                  <input id="confirmed" @change="updateApplication('confirmed')" v-model="editableApplication.confirmed" class="w-0" type="checkbox" /><label for="confirmed" class="box-right"></label>
                </div>
                <div></div>
              </div>
              <div class="w-full text-center">
                <button @click="planInterview('')" class="positive mx-2 mt-2" :title="cancelToolTip">
                  Intervieweinladung
                </button>
              </div>
            </div>
            <div v-if="interviews >= 2">
              <div class="w-full text-left mt-2 py-1 flex flex-row">
                <span class="font-bold text">Termin 2: </span>
                <div class="lign-middle text-right grow">
                  <input class="w-48 inline-block font-semibold text-left" type="datetime-local" v-model="editableApplication.scheduled2" @change="updateApplication('scheduled2')" />
                </div>
              </div>
              <div class="w-full text-left py-1 flex flex-row">
                <span class="grow text">Bestätigt:</span>
                <div class="align-middle text-left flex w-36">
                  <input id="confirmed2" @change="updateApplication('confirmed2')" v-model="editableApplication.confirmed2" class="w-0" type="checkbox" /><label for="confirmed2" class="box-right"></label>
                </div>
              </div>
              <div class="w-full text-center">
                <button @click="planInterview('2')" class="positive mx-2 mt-2" :title="cancelToolTip">
                  Intervieweinladung
                </button>
              </div>
            </div>
            <div v-if="interviews >= 3">
              <div class="w-full text-left mt-2 py-1 flex flex-row">
                <span class="font-bold text">Termin 3: </span>
                <div class="lign-middle text-right grow">
                  <input class="w-48 inline-block font-semibold text-left" type="datetime-local" v-model="editableApplication.scheduled3" @change="updateApplication('scheduled3')" />
                </div>
              </div>
              <div class="w-full text-left py-1 flex flex-row">
                <span class="grow text">Bestätigt:</span>
                <div class="align-middle text-left flex w-36">
                  <input id="confirmed3" @change="updateApplication('confirmed3')" v-model="editableApplication.confirmed3" class="w-0" type="checkbox" /><label for="confirmed3" class="box-right"></label>
                </div>
              </div>
              <div class="w-full text-center">
                <button @click="planInterview('3')" class="positive mx-2 mt-2" :title="cancelToolTip">
                  Intervieweinladung
                </button>
              </div>
            </div>
            <div class="pl-4">
              <div class="border-b border-b-black w-full my-2"></div>
            </div>
          </div>
        </div>

        <div class="w-full text-left py-1 flex flex-row">
          <span class="text">Gehaltsvorstellung</span>
          <div class="align-middle text-right grow">
            <input class="w-36 inline-block font-semibold text-left" placeholder="Gehalt" type="number" v-model="editableApplication.salary" @change="updateApplication('salary')" />
          </div>
        </div>

        <div class="w-full text-left py-1 flex flex-row">
          <span class="text">Wohnort</span>
          <div class="align-middle text-right grow">
            <input class="w-36 inline-block font-semibold text-left" placeholder="Wohnort" v-model="editableApplication.city" @change="updateApplication('city')" />
          </div>
        </div>
        <div class="w-full text-left py-1 flex flex-row">
          <span class="text">Geburtsdatum</span>
          <div class="lign-middle text-right grow">
            <input class="w-36 inline-block font-semibold text-left" placeholder="Geburtsdatum" type="date" v-model="editableApplication.birthdate" @change="updateApplication('birthdate')" />
          </div>
        </div>
        <div class="w-full text-left py-1 flex flex-row">
          <span class="text">Wochenstunden</span>
          <div class="w-36 align-middle text-right grow">
            <input class="w-36 inline-block font-semibold text-left" placeholder="h/Woche" type="number" v-model="editableApplication.weekhours" @change="updateApplication('weekhours')" />
          </div>
        </div>
        <div class="w-full text-left py-1 flex flex-row">
          <span class="text">Eintrittsdatum</span>
          <div class="w-24 align-middle text-right grow">
            <input class="w-36 inline-block font-semibold text-left" type="date" v-model="editableApplication.startDate" @change="updateApplication('startDate')" />
          </div>
        </div>

        <div class="w-full mt-5 flex flex-row">
          <div class="inline-block grow text-center">
            <button v-if="mode != 'archive'" @click="sendMail('Cancel')" class="bad mx-2" :title="cancelToolTip">
              Absagen
            </button>
            <button v-if="mode != 'archive'" @click="$emit('sentCancelMail')" class="bad mx-2">
              Löschen
            </button>
            <button v-if="mode == 'archive'" @click="$emit('deleteApplication')" class="bad mx-2">
              Verschieben / Löschen
            </button>
            <button @click="$emit('assignApplication')" class="negative mx-2 mt-2" title="Diesen Bewerber einem Job zuweisen">
              Zu Stelle verschieben
            </button>
          </div>
        </div>
        <div class="mt-7 text-left relative">
          <span class="text-xl">Dokumente</span>
          <div class="iconborder" @click="showAddFileModal = true">
            <font-awesome-icon icon="plus" class="inborder" />
          </div>
          <div v-if="loadingFile" class="inline-block lds-dual-ring"></div>
          <div v-else>
            <span class="font-bold block">Anschreiben:</span>
            <span v-if="application.files.filter(f => f.type == 'Anschreiben').length == 0" class="block"> - </span>
            <div v-else class="relative">
              <div v-for="file in editableApplication.files.filter(f => f.type == 'Anschreiben')" :key="file.id" class="mt-2">
                <div class="w-full flex flex-row">
                  <div class="border-b-2 border-secondary cursor-pointer " @click="getFile(file)"><span v-if="file.name" class="break-all">{{ file.name }}</span><span v-else class="break-all">{{ file.originalName }}</span>
                  </div>
                  <div class="flex content-center justify-end grow flex-wrap">
                    <font-awesome-icon icon="trash" @click="deleteFile(file)" class="pr-1 px-2 text-secondary cursor-pointer" />
                  </div>
                </div>
              </div>
            </div>
            <span class="font-bold block mt-2">Lebenslauf:</span>
            <span v-if="application.files.filter(f => f.type == 'Lebenslauf').length == 0" class="block"> - </span>
            <div v-else class="relative">
              <div v-for="file in editableApplication.files.filter(f => f.type == 'Lebenslauf')" :key="file.id" class="mt-2">
                <div class="w-full flex flex-row">
                  <div class="border-b-2 border-secondary cursor-pointer " @click="getFile(file)"><span v-if="file.name" class="break-all">{{ file.name }}</span><span v-else class="break-all">{{ file.originalName }}</span>
                  </div>
                  <div class="flex content-center justify-end grow flex-wrap">
                    <font-awesome-icon icon="trash" @click="deleteFile(file)" class="pr-1 px-2 text-secondary cursor-pointer" />
                  </div>
                </div>
              </div>
            </div>
            <span class="font-bold block mt-2">Qualifikationen:</span>
            <span v-if="application.files.filter(f => f.type == 'Qualifikationen').length == 0" class="block"> - </span>
            <div v-else class="relative">
              <div v-for="file in editableApplication.files.filter(f => f.type == 'Qualifikationen')" :key="file.id" class="mt-2">
                <div class="w-full flex flex-row">
                  <div class="border-b-2 border-secondary cursor-pointer " @click="getFile(file)"><span v-if="file.name" class="break-all">{{ file.name }}</span><span v-else class="break-all">{{ file.originalName }}</span>
                  </div>
                  <div class="flex content-center justify-end grow flex-wrap">
                    <font-awesome-icon icon="trash" @click="deleteFile(file)" class="pr-1 px-2 text-secondary cursor-pointer" />
                  </div>
                </div>
              </div>
            </div>
            <span class="font-bold block mt-2">Sonstiges:</span>
            <span v-if="application.files.filter(f => ['', 'Sonstiges'].includes(f.type)).length == 0" class="block"> - </span>
            <div v-else class="relative">
              <div v-for="file in editableApplication.files.filter(f => ['', 'Sonstiges'].includes(f.type))" :key="file.id" class="mt-2">
                <div class="w-full flex flex-row">
                  <div class="border-b-2 border-secondary cursor-pointer " @click="getFile(file)"><span v-if="file.name" class="break-all">{{ file.name }}</span><span v-else class="break-all">{{ file.originalName }}</span>
                  </div>
                  <div class="flex content-center justify-end grow flex-wrap">
                    <font-awesome-icon icon="trash" @click="deleteFile(file)" class="pr-1 px-2 text-secondary cursor-pointer" />
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
        <div class="mt-7">
          <div class="w-full text-left">
            <span class="text-xl">Kommentare</span>
            <div class="iconborder" @click="showAddComment = true">
              <font-awesome-icon icon="plus" class="inborder" />
            </div>
          </div>
          <div v-if="showAddComment" class="relative p-0 pt-3">
            <textarea v-model="newComment" class="border p-3 w-full h-20 bg-gray-100 resize-none"></textarea>
            <div class="w-full text-right">
              <button class="py-0 px-1 rounded-2xl" @click="addComment()">
                Hinzufügen
              </button>
            </div>
          </div>
          <div>
            <div class="w-full text-left border-b mb-2" v-for="comment in comments" :key="comment.id">
              <div>
                <span class="text-xs font-bold text-primary">{{
        comment.author
      }}</span><font-awesome-icon icon="thumbtack" class="float-right mt-1 cursor-pointer" :class="{ 'text-primary': comment.pinned }" @click="pinComment(comment)" />
              </div>
              <pre class="w-full whitespace-pre-wrap
              overflow-y-auto">{{
        comment.message
                }}</pre>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/API/Business";
import { useCookies } from "vue3-cookies";
import AddFileModal from "./AddFileModal.vue";
import TopBanner from "./TopBanner.vue";
import Encryption from "@/Encryption";
export default {
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  components: {
    AddFileModal,
    TopBanner
  },
  expose: ["loadComments"],
  props: {
    application: Object,
    cryptKey: String,
    currentJob: Object,
    mode: String,
    hr: Boolean,
  },
  emits: [
    "closeDetails",
    "fileReceived",
    "applicationUpdated",
    "sentCancelMail",
    "deleteOrArchiveApplication",
    "assignApplication",
    "deleteApplication",
    "banner",
  ],
  data() {
    return {
      plannedInterview: "",
      interviewDate: "",
      editableApplication: {},
      editName: false,
      comments: [],
      loadingFile: false,
      newComment: "",
      showAddComment: false,
      cancelToolTip: "",
      interviews: 1,
      displayInterviewPopup: false,
      showAddFileModal: false,
      bannerText: "",
    };
  },
  methods: {
    planInterview(type) {
      this.plannedInterview = "scheduled" + type;
      this.interviewDate = this.editableApplication[this.plannedInterview];
      this.displayInterviewPopup = true;
    },
    setupInterview() {
      this.displayInterviewPopup = false;
      this.editableApplication[this.plannedInterview] = this.interviewDate;
      this.editableApplication.status = "Interview";
      setTimeout(() => {
        this.updateApplication(this.plannedInterview);
      }, 500);
      this.updateApplication("status");
      this.sendMail("Interview");
    },
    sendMail(type) {
      let vm = this;
      if (this.editableApplication.status == "Abgesagt") {
        if (type == "Cancel") {
          vm.$emit("sentCancelMail");
        }
        return;
      }
      API.getTemplates((templates) => {
        let text = templates["template"+type];
        let es = Date.parse(this.interviewDate);
        if (!isNaN(es)) {
          let d = new Date(es).toLocaleString("de-DE");
          text = text.replace("{Interviewdatum}", d.split(",")[0]);
          text = text.replace(
            "{Interviewuhrzeit}",
            d.split(",")[1].substr(0, d.split(",")[1].length - 3)
          );
        }
        text = text.replace("{Name}", vm.editableApplication.name);
        text = text.replace("{Jobtitel}", vm.currentJob.title);
        //text = text.replaceAll("\n", "%0D%0A");
        text = encodeURIComponent(text)
        window.open(
          "mailto:" +
          vm.editableApplication.mail +
          "?subject=" +
          templates["subject"+type] +
          "&body=" +
          text,
          "_blank"
        );
        if (type == "Cancel") {
          vm.$emit("sentCancelMail");
        }
      });
    },
    getFile(file) {
      let vm = this;
      vm.loadingFile = true;
      API.getFile(file.id, this.cryptKey, (receivedFile) => {
        vm.loadingFile = false
        if (receivedFile) {
          if (navigator.pdfViewerEnabled) {
            vm.$emit("fileReceived", receivedFile);
            vm.loadingFile = false;
          }
          else {
            let ct = "text/plain";
            let contenttypes = {
              ovpn: "text/plain",
              pdf: "application/pdf",
              jpg: "image/jpeg",
              jpeg: "image/jpeg",
              png: "image/png",
            };
            let fileType = "unknown";
            if (receivedFile.originalName) {
              fileType = receivedFile.originalName.split(".").slice(-1)[0];
            }
            if (Object.keys(contenttypes).includes(fileType)) {
              ct = contenttypes[fileType];
            }
            const link = document.createElement('a');
            // create a blobURI pointing to our Blob
            link.href = window.URL.createObjectURL(Encryption.b64toBlob(receivedFile.data, ct));
            link.download = receivedFile.name;
            // some browser needs the anchor to be in the doc
            document.body.append(link);
            link.click();
            link.remove();
          }
        }
      });


    },
    loadComments() {
      let vm = this;
      API.getCommentsForApplication(
        this.editableApplication,
        this.cryptKey,
        (comments) => {
          vm.comments = comments;
          vm.newComment = "";
          vm.editableApplication.comments = comments.length;
        }
      );
    },
    addFile(file) {
      this.editableApplication.files.push(file);
      this.showAddFileModal = false;
      this.uploadApplicationFile(file);
    },
    deleteFile(file) {
      let vm = this;
      API.deleteFile(file, (s) => {
        if (s) {
          vm.$emit("applicationUpdated", "files");
        }
      });
    },
    uploadApplicationFile(file) {
      let vm = this;
      file.application = this.editableApplication.id;
      API.addFile(file, this.cryptKey, (s) => {
        if (s) {
          file = s;
          vm.$emit("applicationUpdated", "files");
        }
      });
    },
    addComment() {
      let vm = this;
      let comment = {
        message: this.newComment,
        application: this.application.id,
      };
      API.addComment(comment, this.cryptKey, (s) => {
        if (s) {
          vm.loadComments();
          vm.showAddComment = false;
        }
      });
    },
    pinComment(comment) {
      API.pinComment(comment, (s) => {
      });
      comment.pinned = !comment.pinned
      if (comment.pinned) {
        this.editableApplication.pinnedComments.push(comment)
      } else {
        this.editableApplication.pinnedComments.splice(this.editableApplication.pinnedComments.indexOf(comment), 1);
      }
      
    },
    encryptApplication() {
      let vm = this;
      API.updateApplication(this.editableApplication, this.cryptKey, this.editableApplication.id, () => {
        vm.$emit("applicationUpdated", "status");
      })
    },
    updateApplication(field) {
      let update = {};
      update[field] = this.editableApplication[field];
      let vm = this;
      API.updateApplication(
        update,
        this.cryptKey,
        this.editableApplication.id,
        (success) => {
          if (success) {
            if (field == "status") {
              vm.$emit("applicationUpdated", "status");
            } else {
              vm.$emit("applicationUpdated");
            }
          }
        }
      );
    },
  },
  created: function () {
    this.editableApplication = this.application;
    if (this.application.scheduled2) this.interviews++;
    if (this.application.scheduled3) this.interviews++;
    this.loadComments();
    setTimeout(() => {
      if (!this.hr) {
        let els = Array.from(
          document
            .getElementById("detailsSidebar")
            .getElementsByTagName("input")
        ).concat(
          Array.from(
            document
              .getElementById("detailsSidebar")
              .getElementsByTagName("select")
          )
        );
        for (let i = 0; i < els.length; i++) {
          els[i].disabled = true;
        }
      }
    }, 500);
  },
  watch: {
    application(newApplication, oldApplication) {
      this.editableApplication = newApplication;
      if (newApplication) {
        this.loadComments();
        this.showAddComment = false;
      }

    },
  },
  computed: {
    initials: function () {
      let nameparts = this.application.name.trim().split(" ");
      if (nameparts == "") {
        return "N/A";
      }
      if (nameparts.length == 1) {
        return nameparts[0][0];
      }
      return nameparts[0][0] + nameparts[nameparts.length - 1][0];
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.detailscontent {
  width: 100%;
  height: 100%;
  border-left: 4px solid #a3bbc7;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
}

option,
option:checked {
  background: #292f4c;
}

input,
select {
  padding: unset;
  border: unset;
  background: unset;
  margin: unset;
}

select {
  width: unset;
}
</style>